@font-face {
  font-family: 'Noto Sans KR';
  font-display: fallback;

  src: url('./NotoSansKR-Thin.otf') format('opentype'), url('./NotoSansKR-Thin.woff') format('woff'),
    url('./NotoSansKR-Thin.woff2') format('woff2');

  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-display: fallback;

  src: url('./NotoSansKR-Light.otf') format('opentype'),
    url('./NotoSansKR-Light.woff') format('woff'), url('./NotoSansKR-Light.woff2') format('woff2');

  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-display: fallback;

  src: url('./NotoSansKR-Regular.otf') format('opentype'),
    url('./NotoSansKR-Regular.woff') format('woff'),
    url('./NotoSansKR-Regular.woff2') format('woff2');

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-display: fallback;

  src: url('./NotoSansKR-Medium.otf') format('opentype'),
    url('./NotoSansKR-Medium.woff') format('woff'), url('./NotoSansKR-Medium.woff2') format('woff2');

  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-display: fallback;

  src: url('./NotoSansKR-Bold.otf') format('opentype'), url('./NotoSansKR-Bold.woff') format('woff'),
    url('./NotoSansKR-Bold.woff2') format('woff2');

  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-display: fallback;

  src: url('./NotoSansKR-Black.otf') format('opentype'),
    url('./NotoSansKR-Black.woff') format('woff'), url('./NotoSansKR-Black.woff2') format('woff2');

  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'NanumSquareRound';
  font-style: normal;
  font-weight: 300;
  src: local('NanumSquareRoundL'),
    /* computer */
    url('NanumSquareRoundL.eot'),
    /* IE9 Compat Modes */
    url('NanumSquareRoundL.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('NanumSquareRoundL.woff2') format('woff2'),
    /* Modern Browsers */
    url('NanumSquareRoundL.woff') format('woff'),
    /* Modern Browsers */
    url('NanumSquareRoundL.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'NanumSquareRound';
  font-style: normal;
  font-weight: 400;
  src: local('NanumSquareRoundR'),
    /* computer */
    url('NanumSquareRoundR.eot'),
    /* IE9 Compat Modes */
    url('NanumSquareRoundR.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('NanumSquareRoundR.woff2') format('woff2'),
    /* Modern Browsers */
    url('NanumSquareRoundR.woff') format('woff'),
    /* Modern Browsers */
    url('NanumSquareRoundR.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'NanumSquareRound';
  font-style: normal;
  font-weight: 700;
  src: local('NanumSquareRoundB'),
    /* computer */
    url('NanumSquareRoundB.eot'),
    /* IE9 Compat Modes */
    url('NanumSquareRoundB.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('NanumSquareRoundB.woff2') format('woff2'),
    /* Modern Browsers */
    url('NanumSquareRoundB.woff') format('woff'),
    /* Modern Browsers */
    url('NanumSquareRoundB.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'NanumSquareRound';
  font-style: normal;
  font-weight: 800;
  src: local('NanumSquareRoundEB'),
    /* computer */
    url('NanumSquareRoundEB.eot'),
    /* IE9 Compat Modes */
    url('NanumSquareRoundEB.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('NanumSquareRoundEB.woff2') format('woff2'),
    /* Modern Browsers */
    url('NanumSquareRoundEB.woff') format('woff'),
    /* Modern Browsers */
    url('NanumSquareRoundEB.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'GMarketSans';
  src: url('GmarketSansTTFMedium.woff') format('woff'),
    url('GmarketSansTTFMedium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}